<template>
  <ContentWrapper :links="topLinks">
    <Form inline>
      <FormItem>
        <Input v-model="queryForm.keyword" type="text" placeholder="搜索" />
      </FormItem>
      <FormItem>
        <FormItem>
          <Select v-model="queryForm.status" placeholder="状态">
            <Option :value="-1">不限</Option>
            <Option :value="1">正常</Option>
            <Option :value="0">下架</Option>
          </Select>
        </FormItem>
      </FormItem>
      <FormItem>
        <Select v-model="queryForm.type" placeholder="商品类型">
          <Option :value="-1">不限</Option>
          <Option :value="0">全新</Option>
          <Option :value="1">二手</Option>
        </Select>
      </FormItem>
      <FormItem>
        <DatePicker
          v-model="createdAtRange"
          type="daterange"
          placement="bottom-end"
          placeholder="创建日期范围"
          style="width: 200px"
        ></DatePicker>
      </FormItem>
      <!-- <FormItem>
        <Button>重置搜索</Button>
      </FormItem> -->
    </Form>
    <Table stripe :columns="columns" :data="itemList" @on-row-dblclick="showItem" :loading="tableLoading">
      <template #openUrl="{ row }">
        <Button type="info" size="small" @click="openUrl(row.h5url)">打开h5</Button>
      </template>
      <template #qrcode="{ row }">
        <Poptip trigger="hover" title="">
          <Button type="info">二维码</Button>
          <div slot="content">
            <QrcodeVue :value="row.h5url" background="#EEF0F3" size="120" />
          </div>
        </Poptip>
      </template>
    </Table>
    <Pager :total="itemListCount" :current.sync="page" />
    <Modal v-model="showItemDetail" title="商品详情" @on-ok="onNewSubmit" width="65%">
      <ItemDetail :alias="currentItem" ref="editor" />
    </Modal>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import Pager from "@/components/Pager.vue"
import { queryItem, changeItemStatus, deleteItem, publishItem } from "@/api"
import { toHumanDate, centiToOne, editAndRemove, imgHolder, apiUtil } from "@/util"
import ItemDetail from "@/components/item/ItemDetail.vue"
import "@/components/tableUtil"
import QrcodeVue from "qrcode.vue"

export default {
  name: "ItemQueryPage",
  components: { ContentWrapper, Pager, ItemDetail, QrcodeVue },
  data() {
    return {
      topLinks: [{ title: "商品列表", link: this.$route.path }],
      createdAtRange: ["", ""],
      queryForm: {
        keyword: "",
        status: null,
        type: null,
        startTime: null,
        endTime: null,
      },
      page: 1,
      tableLoading: false,
      itemList: [],
      itemListCount: 0,
      showItemDetail: false,
      currentItem: "",
      columns: [
        { title: "标识", key: "itemAlias", width: 100, maxWidth: 200, fixed: "left" },
        {
          title: "主图",
          width: 100,
          render: (h, params) => {
            return imgHolder(h, params.row.image?.url)
          },
          fixed: "left",
        },
        {
          title: "标题",
          maxWidth: 200,
          minWidth: 100,
          render: (h, params) => {
            return h(
              "a",
              {
                on: {
                  click: () => {
                    this.showItemDetail = true
                    this.currentItem = params.row.alias
                  },
                },
              },
              [params.row.title],
            )
          },
          fixed: "left",
        },
        {
          title: "卖家",
          render(h, p) {
            return h("div", p.row.seller.nickname)
          },
          width: 100,
          fixed: "left",
        },
        {
          title: "价格",
          render(h, params) {
            return h("div", centiToOne(params.row.price))
          },
        },
        {
          title: "状态",
          render: (h, params) => {
            return h("StatusHelper", {
              props: { value: params.row.status },
              on: {
                change: async (value) => {
                  await apiUtil.call(this, async () => await changeItemStatus(params.row.itemAlias, value))
                },
              },
            })
          },
        },
        {
          title: "打开h5详情页",
          width: 150,
          slot: "openUrl",
          align: "center"
        },
        {
          title: "查看二维码",
          width: 150,
          slot: "qrcode",
          align: "center"
        },
        {
          title: "创建时间",
          key: "createdAt",
          render: (h, p) => h("div", toHumanDate(p.row.createdAt)),
        },
        {
          title: "上架时间",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.listedAt, defText: "-" },
            }),
        },
        {
          title: "下架时间",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.discontinuedAt, defText: "-" },
            }),
        },
        {
          title: "操作",
          width: 150,
          render: (h, params) =>
            editAndRemove.call(
              this,
              h,
              params,
              () => {
                this.edit(params.row.itemAlias)
              },
              async () => {
                await this.delete(params.row.itemAlias)
              },
            ),
        },
      ],
    }
  },
  computed: {},
  watch: {
    async page() {
      await this.reload()
    },
    async createdAtRange() {
      await this.reload()
    },
    queryForm: {
      deep: true,
      async handler() {
        await this.reload()
      },
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      try {
        this.tableLoading = true
        const q = this.queryForm
        let startTime = null,
          endTime = null
        if (this.createdAtRange && this.createdAtRange.length == 2 && this.createdAtRange[0] instanceof Date) {
          startTime = this.createdAtRange[0].getTime()
          endTime = this.createdAtRange[1].getTime()
        }
        let status = null
        if (q.status > -1) {
          status = q.status
        }
        let type = null
        if (q.type > -1) {
          type = q.type
        }
        let userAlias = ""
        const { items, count } = await queryItem(userAlias, this.page, q.keyword, startTime, endTime, status, type)
        this.itemList = items
        this.itemListCount = count
        this.tableLoading = false
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    edit(alias) {
      this.showItemDetail = true
      this.currentItem = alias
    },
    async delete(alias) {
      try {
        await deleteItem(alias)
        await this.reload()
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    showItem(row) {
      this.showItemDetail = true
      this.currentItem = row.itemAlias
    },
    async onNewSubmit() {
      apiUtil.call(
        this,
        async () => {
          const editor = this.$refs.editor
          const formData = Object.assign({}, editor.item)
          console.log(formData)
          if (formData.price == 0) {
            throw new Error("没有设置商品价格")
          }
          const item = {}
          item.price = formData.price * 100
          item.originalPrice = Number.parseFloat(formData.price)
          item.itemType = formData.itemType
          item.title = formData.title
          item.detailContent = formData.detailContent
          item.medias = formData.medias
          item.stockQuantity = formData.stockQuantity
          item.promotion = {}
          item.promotion.term = formData.term
          item.categoryAlias = formData.categoryAlias
          item.shipment = { shippingMethods: [] }
          if (formData.shipment.pickUpBySelf) {
            item.shipment.shippingMethods.push("SELF_PICKUP")
          }
          if (formData.shipment.deliveryByExpress) {
            item.shipment.shippingMethods.push("DELIVERY_BY_EXPRESS")
          }
          if (formData.shipment.shippingMethods.length < 1) {
            throw new Error("没有选择物流方式")
          }
          item.shipment.shippingFeeType = formData.shippingFeeType
          if (formData.shipment.shippingFeeType == "ONE") {
            item.shipment.shippingFee = 0
          } else if (formData.shipment.shippingFeeType == "FIXED") {
            item.shipment.shippingFees = this.form.shipmentFees
          } else if (formData.shipment.shippingFeeType == "CALC") {
            item.sizeAndWeight = {}
            item.sizeAndWeight.width = formData.sizeAndWeight.width * 10
            item.sizeAndWeight.height = formData.sizeAndWeight.height * 10
            item.sizeAndWeight.length = formData.sizeAndWeight.length * 10
            item.sizeAndWeight.weight = formData.sizeAndWeight.weight
          }

          item.status = 1
          console.log("item", item)
          const r = await publishItem(item)
          console.log("r", r)
        },
        () => {
          this.dialogDisplay = true
        },
      )
    },
    openUrl(url) {
      window.open(url)
    }
  },
}
</script>
